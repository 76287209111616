import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'
import moment from 'moment-timezone'
import React from 'react'
import { errorMessage } from '../../utils/axios-error'
import { AuthContext } from './../../contexts/Auth'
import { ReservationType, getReportCSV, getReservationList } from './../../services/evreservation'
import { getEvStationList } from './../../services/evstation'
import ReservationDetail from './ReservationDetail'
import ReservationList from './ReservationList'
const { Content } = Layout

const { Option } = Select

function transformReservationData(rawData: any[]) {
  return rawData.map((item) => {
    const {
      reservationNo,
      status,
      userId,
      nfcId,
      mobile,
      cardType,
      receiptNo,
      receiptFile,
      station,
      charger,
      deviceType,
      reservationStartTime,
      reservationStopTime,
      reserveDurationMinute,
      startChargingTime,
      stopChargingTime,
      usageDurationMinute,
      energy_kWh,
      revenue,
      paymentType,
      channel,
      totalRefunded,
      notes,
    } = item

    const usageData = {
      startTime: startChargingTime
        ? moment(startChargingTime).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        : '-',
      stopTime: stopChargingTime
        ? moment(stopChargingTime).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        : '-',
      chargedTime: usageDurationMinute || '-',
      chargedKwh: energy_kWh || '-',
    }

    const transformedData = {
      key: reservationNo,
      reservationStatus: { reservationNo, status },
      customer: {
        userId,
        nfcId,
        mobile,
        cardType,
      },
      station,
      charger: {
        chargerCode: charger,
        deviceType,
      },
      reserveTime: {
        actualStartTime: moment(reservationStartTime)
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss'),
        actualStopTime: moment(reservationStopTime)
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss'),
        actualReserveMinute: reserveDurationMinute,
      },
      usageData,
      revenue: {
        revenue,
        totalRefunded,
        paymentType: paymentType || 'Free',
      },
      channel,
      receipt: {
        receiptNo,
        receiptFile,
      },
      notes,
    }

    return transformedData
  })
}

interface IStationList {
  _id: number
  stationName: string
}

interface IPagination {
  current: number
  pageSize: number
  total?: number
}

type ReservationState = {
  reservationDetail: any
  currentReservationId: number
  siderCollapsed: boolean
  stationList: any[]
  vehicleList: any[]
  reservationList: any[]
  isLoadStation: boolean
  isLoadReservation: boolean
  pagination: IPagination
  loading: boolean
  isExportModalVisible: boolean
  loadingExport: boolean
  exportCSVContent: string
}

type ReservationProp = {
  prop: ReservationType
  exportRef: any
}

class Reservation extends React.Component<ReservationProp, ReservationState> {
  transformedData: any
  exportRef: any
  formRef = React.createRef<FormInstance>()

  state = {
    reservationDetail: null,
    currentReservationId: 0,
    siderCollapsed: true,
    stationList: [],
    vehicleList: [],
    pagination: {
      current: 1,
      pageSize: 50,
    },
    reservationList: [],
    isLoadStation: true,
    isLoadReservation: true,
    loading: false,
    isExportModalVisible: false,
    loadingExport: true,
    exportCSVContent: '',
  }
  static contextType = AuthContext

  constructor(props: ReservationProp) {
    super(props)
    this.exportRef = React.createRef()
  }

  getReservationDetail = (reservationId: number): void => {
    this.setState({ currentReservationId: reservationId, siderCollapsed: false })
  }

  componentDidMount() {
    if (this.state.isLoadStation) {
      getEvStationList(this.context, { page: 1, limit: 1000 })
        .then((result: { data: any }) => {
          this.setState({ stationList: [...result.data.docs] })
        })
        .then(() => {
          this.setState({ isLoadStation: false })
        })
    }

    if (this.state.isLoadReservation) {
      this.getFleetReservation({})
    }
  }

  getFleetReservation = (data: any): void => {
    this.setState({ reservationList: [], loading: true })
    this.transformedData = []
    getReservationList(this.context, data)
      .then((response: any) => {
        const { docs, totalDocs } = response?.data
        this.setState({
          reservationList: docs,
          pagination: { ...this.state.pagination, total: totalDocs },
          loading: false,
        })
        this.transformedData = transformReservationData(docs)
      })
      .then(() => {
        this.setState({ isLoadReservation: false })
      })
  }

  getSimpleVehicleList = (data: any): void => {
    this.setState({ reservationList: [], loading: true })
    this.transformedData = []
    getReservationList(this.context, data)
      .then(
        (reservationlist: {
          data: any
          meta: { page: number; results: number; total: number }
        }) => {
          const { total } = reservationlist.meta
          this.setState({
            reservationList: [...reservationlist.data],
            pagination: { ...this.state.pagination, total: total },
            loading: false,
          })
          this.transformedData = transformReservationData(reservationlist.data)
        },
      )
      .then(() => {
        this.setState({ isLoadReservation: false })
      })
  }

  handleTableChange = (pagination: IPagination): void => {
    const data = { page: pagination.current, pageSize: pagination.pageSize }
    this.getFleetReservation(data)
    this.setState({ pagination })
  }

  getFilteredReservation = (data: any): void => {
    this.setState({ pagination: { ...this.state.pagination, current: 1 } })
    this.getFleetReservation(data)
  }

  showModal = () => {
    this.setState({ isExportModalVisible: true })
    const params = this.formRef.current?.getFieldsValue()
    getReportCSV(this.context, params || {})
      .then((result) => {
        const universalBOM = '\uFEFF'
        const content =
          'data:application/csv;charset=utf-8,' + encodeURIComponent(universalBOM + result)
        this.setState({ ...this.state, exportCSVContent: content })
      })
      .catch((error) => {
        console.error(error)
        Modal.error({
          content: errorMessage(error),
        })
      })
      .finally(() => {
        this.setState({ ...this.state, loadingExport: false })
      })
  }

  handleOk = () => {
    this.setState({ isExportModalVisible: false })
  }

  handleCancel = () => {
    this.setState({ isExportModalVisible: false })
  }

  onCloseDetail = () => {
    this.setState({ siderCollapsed: true })
  }

  render() {
    return (
      <>
        <Modal
          title="Export Reservation"
          visible={this.state.isExportModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          style={{ textAlign: 'center' }}
          footer={[
            <Button
              key="link"
              href={this.state.exportCSVContent}
              type="primary"
              loading={this.state.loadingExport}
              download="report.csv"
              onClick={this.handleOk}
            >
              Download
            </Button>,
          ]}
        >
          {this.state.loadingExport ? (
            <Space direction="vertical">
              <Spin tip="Loading..."></Spin>
              <Alert type="info" message="Please wait. This might take up to 3 minutes." />
            </Space>
          ) : (
            'Report file is ready to download.'
          )}
        </Modal>
        <Row style={{ height: '100%' }} wrap={false}>
          <Col span={this.state.siderCollapsed ? 24 : 16} style={{ height: '100%' }}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Form ref={this.formRef} layout="inline" onFinish={this.getFilteredReservation}>
                <Space wrap>
                  <Form.Item name="reservation_id" label="Reservation No.">
                    <InputNumber />
                  </Form.Item>
                  <Form.Item name="query" label="Search">
                    <Input placeholder="mobile/nfc id/reservation id/userid" />
                  </Form.Item>
                  <Form.Item name="status" label="Status" initialValue={''}>
                    <Select style={{ width: 120 }}>
                      <Option value="">ALL</Option>
                      <Option value="REQUEST">REQUEST</Option>
                      <Option value="RESERVE">RESERVE</Option>
                      <Option value="ON_GOING">ON_GOING</Option>
                      <Option value="FINISH">FINISH</Option>
                      <Option value="COMPLETE">COMPLETE</Option>
                      <Option value="REJECT">REJECT</Option>
                      <Option value="CANCEL">CANCEL</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="refunded" label="Only Refunded" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                  <Form.Item name="station_id" label="Station" initialValue={''}>
                    <Select showSearch style={{ width: 250 }} optionFilterProp="children">
                      <Option value="">ALL-STATION</Option>
                      {!this.state.isLoadStation &&
                        this.state.stationList.map((option: IStationList) => (
                          <Option key={option._id} value={option._id}>
                            ({option._id}) {option.stationName}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="start_time" label="From">
                    <DatePicker format="YYYY-MM-DD" />
                  </Form.Item>
                  <Form.Item name="stop_time" label="To">
                    <DatePicker />
                  </Form.Item>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button onClick={this.showModal}>Export</Button>
                </Space>
              </Form>
              <br />
              <ReservationList
                dataSource={this.transformedData}
                getReservationDetail={this.getReservationDetail}
                pagination={this.state.pagination}
                onTableChange={this.handleTableChange}
                loading={this.state.loading}
              />
            </div>
          </Col>

          {!this.state.siderCollapsed && (
            <Col span={8} style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
              <ReservationDetail
                reservationId={this.state.currentReservationId}
                onClose={this.onCloseDetail}
              />
            </Col>
          )}
        </Row>
      </>

      // </Layout>
    )
  }
}

export default Reservation
