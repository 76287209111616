import axios from 'axios'
import config from '../../config'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

interface IOwner {
  name: any
  userId: any
}

interface ILocation {
  name: any
  latitude: number
  longitude: number
}

interface IServiceTime {
  dayOfWeek: any
  fromTime: any
  toTime: any
}

interface IPaginate {
  page?: number
  limit?: number
}

export interface ICreateStation {
  chargers: string[]
  stationName: string
  nfcMasters: string[]
  stationStatus: string
  location: ILocation
  serviceStatus: string
  serviceTime: IServiceTime[]
  accomodations: string[]
  serviceGroup: string
  owner: IOwner
  platformType: string
  userId?: number
  name?: string
  _id?: number
  photos: string[]
  TimeService?: any
  lat?: number
  long?: number
}

export async function getEvStationList(
  authContext: IAuthContext,
  paginate?: IPaginate,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/ev-admin/station`, {
        headers: {
          authorization: userToken,
        },
        params: { page: paginate?.page, limit: paginate?.limit },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getAccomoDations(authContext: IAuthContext): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/cms/evcharger/accomodation/list`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function createEvStation(
  authContext: IAuthContext,
  data: ICreateStation,
): Promise<any> {
  const userToken = authContext?.userLoggedIn?.userToken

  return new Promise((resolve, reject) => {
    axios
      .post(`${restfulService.URL}/cms/evcharger/station`, data, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function updateEvStation(
  data: ICreateStation,
  authContext: IAuthContext,
  params: number,
): Promise<any> {
  const userToken = authContext?.userLoggedIn?.userToken
  return new Promise((resolve, reject) => {
    axios
      .put(`${restfulService.URL}/cms/evcharger/station/${params}`, data, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        console.log('success')
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function deleteEvStation(authContext: IAuthContext, params: number): Promise<any> {
  const userToken = authContext?.userLoggedIn?.userToken

  return new Promise((resolve, reject) => {
    axios
      .delete(`${restfulService.URL}/cms/evcharger/station/${params}`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
