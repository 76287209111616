import axios from 'axios'
import config from '../../config'

const { restfulService } = config

export interface IAuthContext {
  user: any
  userLoggedIn: any
}
export interface IPostPicing {
  _id?: number
  priceCode: string
  priceDescription: string | null
  basePrice: IBaseHourPrice
  electricPrice: number
  hourPrice: IBaseHourPrice
  BaseServiceFee?: number
  parkingFee?: number
  penaltyFeePerHour: number
  penaltyFee?: number
}

interface IBaseHourPrice {
  serviceFee: number | any
  parkingFee?: number | any
}

export async function getPricingList(authContext: IAuthContext, page?: number): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .get(`${restfulService.URL}/ev-admin/pricing`, {
        headers: {
          authorization: userToken,
        },
        params: { page },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function createPricing(data: IPostPicing, authContext: IAuthContext): Promise<any> {
  const userToken = authContext?.userLoggedIn?.userToken

  return new Promise((resolve, reject) => {
    axios
      .post(`${restfulService.URL}/cms/pricing`, data, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        console.log('success')
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function updatePricing(
  data: IPostPicing,
  authContext: IAuthContext,
  params: number,
): Promise<any> {
  const userToken = authContext?.userLoggedIn?.userToken
  return new Promise((resolve, reject) => {
    axios
      .put(`${restfulService.URL}/cms/pricing/${params}`, data, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        console.log('success')
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function deletePricing(authContext: IAuthContext, params: number): Promise<any> {
  const userToken = authContext?.userLoggedIn?.userToken
  return new Promise((resolve, reject) => {
    axios
      .delete(`${restfulService.URL}/cms/pricing/${params}`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        console.log('success')
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
