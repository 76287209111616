import { EyeOutlined } from '@ant-design/icons'
import { Col, Collapse, Descriptions, Modal, Row, Space, Table, Typography } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ViewButton } from '../../components/StyledComponent'
import { getEvCharger } from '../../services/evcharger'

const { Title } = Typography
const { Panel } = Collapse

interface IChargerData {
  chargerCode: string
  _id: number
  deviceInfo: []
  stationId: number
  chargerStatus: string
  deviceType: string
  deviceProtocol: string
  serviceGroup: string
  connectors: []
}

const HeadText = styled.h4`
  font-weight: 500;
  display: inline-flex;
`
const mockData = {
  vendor: '',
  model: '',
  serialNumber: '',
  firmwareVersion: '',
  stationId: '',
  chargerCode: '',
  chargerStatus: '',
  deviceType: '',
  deviceProtocol: '',
  serviceGroup: '',
  priceId: '',
}

const ViewCharger = (): JSX.Element => {
  const [chargerList, setChargerList] = useState<any>([])
  const [loadingCharger, setLoadingCharger] = useState(true)
  const [dataConnector, setDataConnector] = useState<any>([])
  const [visible, setVisible] = useState(false)
  const [current, setCurrent] = useState(1)
  const [totalDocs, setTotalDocs] = useState(1)
  const [limitDocs, setLimitDocs] = useState(0)
  const [isModalPricing, setIsModalPricing] = useState(false)

  useEffect(() => {
    getDataFromApi(current)
  }, [])

  const getDataFromApi = (page: number) => {
    setLoadingCharger(true)
    getEvCharger(page).then((result: any) => {
      const { docs, totalDocs, limit } = result?.data
      const charger = docs.map((doc: any) => ({ ...doc, key: `charger-${docs._id}` }))
      setTotalDocs(totalDocs)
      setLimitDocs(limit)
      setChargerList(charger)
      setLoadingCharger(false)
    })
  }
  const handleChangePagination = (page: number) => {
    setCurrent(page)
    getDataFromApi(page)
  }
  const handleShowDataPricing = () => {
    setIsModalPricing(true)
  }

  const handleShowData = (value: any) => {
    setVisible(true)
    const {
      deviceInfo,
      stationId,
      chargerCode,
      chargerStatus,
      deviceType,
      deviceProtocol,
      serviceGroup,
      connectors,
    } = value || {}
    setDataConnector(connectors)
    const { vendor, model, serialNumber, firmwareVersion } = deviceInfo || {}
    mockData.vendor = vendor
    mockData.model = model
    mockData.serialNumber = serialNumber
    mockData.firmwareVersion = firmwareVersion
    mockData.stationId = stationId
    mockData.chargerCode = chargerCode
    mockData.chargerStatus = chargerStatus
    mockData.deviceType = deviceType
    mockData.deviceProtocol = deviceProtocol
    mockData.serviceGroup = serviceGroup
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: 'chargerId',
    },
    {
      title: 'Charger Code',
      dataIndex: 'chargerCode',
      key: 'chargerCode',
    },
    {
      title: 'Status',
      dataIndex: 'chargerStatus',
      key: 'chargerStatus',
    },
    {
      title: 'Service Group',
      dataIndex: 'serviceGroup',
      key: 'serviceGroup',
    },
    {
      title: 'Device Protocol',
      dataIndex: 'deviceProtocol',
      key: 'deviceProtocol',
    },
    {
      title: 'Action',
      key: '_id',
      render: (item: any, index: any) => (
        <>
          <Space size="middle">
            <ViewButton shape="round" onClick={() => handleShowData(item)} key={item} value={item}>
              <EyeOutlined /> View
            </ViewButton>
          </Space>
        </>
      ),
    },
  ]
  return (
    <>
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title level={1}>Charger List</Title>
          {/* <ButtonGlobal
                shape="round"
                type="primary"
                style={{ top: 11, left: 10 }}
                onClick={handleChangePage}
              >
                add charger
              </ButtonGlobal> */}
          <Title level={4}>
            Total Charger : {loadingCharger !== false ? loadingCharger : totalDocs}
          </Title>
        </div>
        <hr style={{ marginBottom: 20 }} />
        <HeadText style={{ fontSize: 15, marginLeft: 10 }}></HeadText>
        <Table
          columns={columns}
          dataSource={chargerList}
          loading={loadingCharger}
          pagination={{
            pageSize: limitDocs,
            current: current,
            total: totalDocs,
            onChange: handleChangePagination,
            showSizeChanger: false,
          }}
        />
      </>

      <Modal
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <Descriptions title="Charger Detail" labelStyle={{ fontWeight: 'bolder' }} bordered>
          <Descriptions.Item label="Device Info" span={1}>
            <p>
              <HeadText>vendor :</HeadText>
              {mockData.vendor}
              <hr />
              <HeadText>model : </HeadText>
              {mockData.model}
              <hr />
              <HeadText>serial :</HeadText>
              {mockData.serialNumber}
              <hr />
              <HeadText>firmware : </HeadText> {mockData.firmwareVersion}
              <hr />
            </p>
          </Descriptions.Item>
          <Descriptions.Item label="connectors" span={4}>
            {dataConnector.map((item: any) => (
              <Collapse>
                <Panel header="connectors info" key="1">
                  <Row>
                    <Col md={12} sm={24} xs={24}>
                      <p>
                        <Row>
                          <HeadText>status : </HeadText>
                          {item.connectorStatus}
                        </Row>
                        <Row>
                          <HeadText>maxKw : </HeadText>
                          {item.maxKw}
                        </Row>
                        <Row>
                          <HeadText>selector : </HeadText>
                          {item.connectorSelector}
                        </Row>
                      </p>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <HeadText>connectorType : </HeadText>
                      <p>code: {item.connectorType.code}</p>
                      <p>name: {item.connectorType.name}</p>
                      <p>current: {item.connectorType.currentType}</p>
                    </Col>
                  </Row>
                  <Row>
                    {item?.pricing ? (
                      <>
                        <ViewButton
                          shape="round"
                          onClick={handleShowDataPricing}
                          key={item}
                          value={item}
                        >
                          <EyeOutlined /> pricing
                        </ViewButton>
                        <Modal
                          centered
                          open={isModalPricing}
                          onOk={() => setIsModalPricing(false)}
                          onCancel={() => setIsModalPricing(false)}
                          footer={null}
                          width={700}
                        >
                          <>
                            <Row>
                              <Title level={5}>Pricing Info </Title>
                              <p style={{ margin: 2 }}>( ID: {item?.priceId} )</p>
                            </Row>
                            <Descriptions layout="vertical" bordered>
                              <Descriptions.Item label="Price Code">
                                {item?.pricing?.priceCode}
                              </Descriptions.Item>
                              <Descriptions.Item label="Price Description">
                                {!`${item?.pricing?.priceDescription}` == null
                                  ? `${item?.pricing?.priceDescription}`
                                  : 'not specified'}
                              </Descriptions.Item>
                              <Descriptions.Item label="electricPrice">
                                {item?.pricing?.electricPrice} ฿ / kWh
                              </Descriptions.Item>
                              <Descriptions.Item label="PenaltyFee Per Hour">
                                {item?.pricing?.penaltyFeePerHour} ฿ / kWh
                              </Descriptions.Item>
                              <Descriptions.Item label="Base Price">
                                <b>serviceFee:&nbsp;&nbsp;</b>
                                {item?.pricing?.serviceFee} ฿ / hour
                              </Descriptions.Item>
                              <Descriptions.Item label="hourPrice">
                                <b>serviceFee:&nbsp;&nbsp;</b>
                                {item?.pricing?.hourPrice?.serviceFee}
                                ฿ / hour <br />
                                <b>parkingFee:&nbsp;&nbsp;</b>
                                {item?.pricing?.hourPrice?.parkingFee} ฿ / hour
                              </Descriptions.Item>
                            </Descriptions>
                          </>
                        </Modal>
                      </>
                    ) : (
                      <p style={{ color: 'red' }}>! This connector not pricing</p>
                    )}
                  </Row>
                </Panel>
              </Collapse>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="StationID">
            <p>{mockData.stationId}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Charger Code">
            <p>{mockData.chargerCode}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Charger Status">
            <p>{mockData.chargerStatus}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Device Type">
            <p>{mockData.deviceType}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Device Protocol">
            <p>{mockData.deviceProtocol}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Service Group">
            <p>{mockData.serviceGroup}</p>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  )
}

export default ViewCharger
