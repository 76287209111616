import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { Drawer, Grid, Layout, Menu, MenuProps } from 'antd'
import { Breakpoint } from 'antd/lib/_util/responsiveObserve'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from '../../contexts/Auth'
import LogoHaupLong from './logo2.png'
import LogoHaup from './logo3.png'

const { Sider } = Layout

type SubMenuItem = {
  title: string
  key: string
  link: string
}

type Logo = {
  icon: string
  width: string
}

type MenuItem = {
  key: string
  title: string
  link: string
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | any
  subMenu?: SubMenuItem[]
  onlyAdmin?: boolean
}

interface ISideMenu {
  collapsed: boolean
  onCollapse: (collapsed: boolean) => void
  menus: MenuItem[]
  screen?: Partial<Record<Breakpoint, boolean>>
}

interface ISideMenuState extends ISideMenu {
  logo: Logo
  mobileMenuOpen: boolean
}
export const UlCustom = styled.ul`
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
`

const logoList = {
  long: { icon: LogoHaupLong, width: '120px' },
  short: { icon: LogoHaup, width: '40px' },
}

const SideMenu = (props: ISideMenu) => {
  const auth = useAuth()
  const screen = Grid.useBreakpoint()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const logo = useMemo<Logo>(() => {
    if (props.collapsed) {
      return logoList.short
    }
    return logoList.long
  }, [props.collapsed])

  const menus = useMemo<MenuProps['items']>(() => {
    return props.menus
      .filter((menu) => {
        if (menu.onlyAdmin === true) {
          if (auth.isAdmin !== true) {
            return false
          }
        }
        return true
      })
      .map((item) => {
        const submenu: MenuProps['items'] = item.subMenu?.map((sub) => ({
          label: <Link to={sub.link}>{sub.title}</Link>,
          key: sub.key,
        }))
        return {
          label: <Link to={item.link}>{item.title}</Link>,
          key: item.key,
          children: submenu,
        }
      })
  }, [auth.isAdmin, props.menus])

  return (
    <div style={{ position: 'relative', backgroundColor: '#15499b' }}>
      <div
        hidden={screen?.md}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '14px',
          zIndex: 1000,
          cursor: 'pointer',
        }}
        onClick={() => setMobileMenuOpen(true)}
      >
        <MenuOutlined style={{ fontSize: '36px', color: 'white' }} />
      </div>
      <Sider
        collapsible
        collapsed={props.collapsed}
        onCollapse={props.onCollapse}
        style={{ background: '#15499b' }}
        className="site-bar-media"
      >
        <Link to="/">
          <div className="logo" style={{ height: '40px' }}>
            <img src={logo.icon} width={logo.width} />
          </div>
        </Link>

        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          style={{ background: '#15499b' }}
          items={menus}
        />

        {/* <div className="hamburger">
            <input type="checkbox" id="toggleHamburger" hidden />
            <label htmlFor="toggleHamburger" className="toggleHamburger">
              <div className="hamburger__icon"></div>
            </label>
            <div className="hamburger__bg" />
            <ul className="hamburger__nav">
              <Menu
                theme="dark"
                defaultSelectedKeys={['1']}
                mode="inline"
                style={{ background: '#15499b' }}
                items={this.menus}
              />
            </ul>
          </div> */}
      </Sider>
      <Drawer
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        bodyStyle={{ backgroundColor: '#15499b' }}
        headerStyle={{ backgroundColor: '#15499b' }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        placement="left"
      >
        <Link to="/">
          <img src={LogoHaupLong} width={150} style={{ margin: 10 }} />
        </Link>
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          style={{ background: '#15499b' }}
          items={menus}
        />
      </Drawer>
    </div>
  )
}

export default SideMenu
