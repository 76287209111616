import styled, { keyframes } from 'styled-components'
import { Button, Card, Col, Input, Radio, Row, Typography } from 'antd'
import {
  slideInRight,
  slideInLeft,
  slideInUp,
  fadeInRight,
  slideInDown,
  fadeInDown,
} from 'react-animations'
const { Search } = Input
const { Title } = Typography
const fadeInDownAnimation = keyframes`${fadeInDown}`
const slideInRightAnimation = keyframes`${slideInRight}`
const slideInLeftAnimation = keyframes`${slideInLeft}`
const slideInUpAnimation = keyframes`${slideInUp}`
const fadeInRightAnimation = keyframes`${fadeInRight}`
const slideInDownAnimation = keyframes`${slideInDown}`

export const SlideInRightDiv = styled.div`
  animation: 1s ${slideInRightAnimation};
`
export const SlideInLeftDiv = styled.div`
  animation: 2s ${slideInLeftAnimation};
`
export const FlipInXDiv = styled.div`
  animation: 2s ${slideInUpAnimation};
`
export const FadeInRightDiv = styled.div`
  animation: 2s ${fadeInRightAnimation};
`
export const SlideInDownDiv = styled.div`
  animation: 2s ${slideInDownAnimation};
`
export const FadeInDownDiv = styled.div`
  animation: 4s ${fadeInDownAnimation};
`

export const CardEffect = styled(Card)`
  border-radius: 15px;
  transition: transform 250ms;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  display: flex;
  flex-direction: column;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6) !important;
  }
`
export const DeleteButton = styled(Button)`
  background-color: #dc3545;
  border-color: #ffffff;
  color: #ffffff;
  &:hover {
    background-color: #ffffff !important;
    border-color: #002140 !important;
    color: #002140 !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  }
  &:focus {
    background-color: #dc3545 !important;
    border-color: #ffffff;
    color: #ffffff;
    &:hover {
      background-color: #ffffff !important;
      border-color: #002140 !important;
      color: #002140 !important;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
    }
  }
`
export const EditButton = styled(Button)`
  background-color: #0d6efd;
  border-color: #ffffff;
  color: #ffffff;
  &:hover {
    background-color: #ffffff !important;
    border-color: #002140 !important;
    color: #002140 !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  }
  &:focus {
    background-color: #0d6efd !important;
    border-color: #ffffff;
    color: #ffffff;
    &:hover {
      background-color: #ffffff !important;
      border-color: #002140 !important;
      color: #002140 !important;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
    }
  }
`
export const ViewButton = styled(Button)`
  background-color: #0dcaf0;
  border-color: #ffffff;
  color: #ffffff;
  &:hover {
    background-color: #ffffff !important;
    border-color: #002140 !important;
    color: #002140 !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  }
  &:focus {
    background-color: #0d6efd !important;
    border-color: #ffffff;
    color: #ffffff;
    &:hover {
      background-color: #ffffff !important;
      border-color: #002140 !important;
      color: #002140 !important;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
    }
  }
`
export const GreyButton = styled(Button)`
  background-color: #6c757d;
  border-color: #6c757d;
  color: #ffffff;
  &:hover {
    background-color: #6c757d;
  }
  &:focus {
    background-color: #6c757d;
  }
`
export const ButtonGlobal = styled(Button)`
  background-color: #002140;
  border-color: #ffffff;
  color: #ffffff;
  &:hover {
    background-color: #ffffff !important;
    border-color: #002140 !important;
    color: #002140 !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  }
  &:focus {
    background-color: #002140 !important;
    border-color: #ffffff;
    color: #ffffff;
    &:hover {
      background-color: #ffffff !important;
      border-color: #002140 !important;
      color: #002140 !important;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
    }
  }
`
export const TextCustomFilter = styled.p`
  font-size: 15px;
  transition: transform 250ms;
  cursor: pointer;
  &:hover {
    background-color: rgb(118, 192, 249, 0.3);
    border-radius: 25px;
  }
`
export const BarCustom = styled.div`
  border-radius: 25px;
  border: 1px solid;
  width: 90%;
  overflow: hidden;
  transition: transform 250ms;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  &:hover {
    transform: translateY(-10px);
  }
`
export const CardEffectCustom = styled(Card)`
  border-radius: 15px;
  transition: transform 250ms;
  margin: 0px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  margin: 15px;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6) !important;
  }
`
export const CardEffectCustomCell = styled(Card)`
  border-radius: 15px;
  border: none;
  transition: transform 250ms;
  margin: 15px;
  background-color: rgb(118, 192, 249, 0.1);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2) !important;
  position: relative;
  top: 16px;
`
export const SpinCustom = styled.p`
  font-size: 30px;
  position: absolute;
  margin-top: 15%;
  margin-left: 35%;
`
export const CurrentFilter = styled(Card)`
  transition: height 2s;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 3px 3px;
  @media only screen and (max-width: 1285px) {
    display: none;
  }
`
export const CurrentFilterMedia = styled(Card)`
  border: 1px solid black;
  top: -1.3rem;
  border-radius: 15px;
  box-shadow: 3px 3px;
  z-index: 100;
  width: 230px;
  transition: transform 250ms;
  position: absolute;
  @media only screen and (max-width: 1285px) {
    display: none;
  }
`
export const SearchCustom = styled(Search)`
  input {
    border-radius: 25px;
    height: 40px;
    border: none;
    :focus {
      border: none !important;
    }
  }
  .ant-input-group-addon {
    border: none !important;
    .ant-btn {
      border: none !important;
    }
  }
`
export const RowCustomData = styled(Row)`
  height: 745px;
  background: #f0f2f4;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 25px;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
`
export const RowCustomDataModal = styled(Row)`
  max-height: 320px;
  background: #f0f2f4;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 25px;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
  margin-top: 35px;
`
export const ColCustomMediaMin = styled(Col)`
  @media only screen and (min-width: 1285px) {
    display: none;
  }
`
export const ColCustomMediaMax = styled(Col)`
  @media only screen and (max-width: 1285px) {
    display: none;
  }
`
export const DivCustom = styled.div`
  font-size: 20px;
  margin-left: -26%;
  font-weight: bold;
  @media only screen and (min-width: 1285px) {
    display: none;
  }
  &:hover ${CurrentFilterMedia} {
    transition: transform 250ms;
    display: block;
    z-index: 300;
  }
`

export const TextTableData = styled.p`
  transition: transform 250ms;
  transition: 0.2s ease-out;
  &:hover {
    color: black;
    background-color: transparent;
    border: black;
    transition: 0.3s;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2) !important;
  }
`

export const TextTableEffect = styled.p`
  transition: 0.2s ease-out;
  &:hover {
    color: black;
    background-color: transparent;
    border: black;
    transition: 0.3s;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
  }
`
export const TitleCardText = styled(Title)`
  position: absolute;
  top: -17px;
  bottom: 0;
  z-index: 500;
  background: #ffffff;
  left: 31px;
  display: table;
`

export const RadioCustom = styled(Radio)`
  .ant-radio {
    top: -34px;
    border: black 0.5px solid;
    border-radius: 25px;
  }
`
