import { combineReducers } from 'redux'
import ContractData from './ContractData'
import CountReducer from './Count'
import ImageInsurance from './ImageInsurance'
import SubmissionReducer from './Submission'

export const AllReducer = combineReducers({
  submissions: SubmissionReducer,
  count: CountReducer,
  ImageInsurance: ImageInsurance,
  contractData: ContractData,
})
