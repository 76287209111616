import { DeleteTwoTone, EyeOutlined } from '@ant-design/icons'
import { Col, Descriptions, Image, Modal, Row, Space, Table, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import MapPicker from 'react-google-map-picker'
import styled from 'styled-components'
import Notification from '../../components/Notification'
import { DeleteButton, ViewButton } from '../../components/StyledComponent'
import configs from '../../config'
import { deleteEvStation, getEvStationList } from '../../services/evstation'
import { AuthContext } from './../../contexts/Auth'
import CreateStation from './index'

const { googleMaps } = configs
const DefaultZoom = 10

const { Title } = Typography
export const TitleTime = styled.p`
  font-size: 13px;
  margin-bottom: 10px;
  color: #1890ff;
  font-weight: 600;
`
const mockData = {
  chargers: [],
  stationName: '',
  stationStatus: '',
  locationName: '',
  latitude: '',
  longitude: '',
  serviceTime: [
    {
      dayOfWeekSun: 'Sunday',
      fromTimeSun: '',
      toTimeSun: '',
    },
    {
      dayOfWeekMon: 'Monday',
      fromTimeMon: '',
      toTimeMon: '',
    },
    {
      dayOfWeekTue: 'Tuesday',
      fromTimeTue: '',
      toTimeTue: '',
    },
    {
      dayOfWeekWed: 'Wednesday',
      fromTimeWed: '',
      toTimeWed: '',
    },
    {
      dayOfWeekThu: 'Thursday',
      fromTimeThu: '',
      toTimeThu: '',
    },
    {
      dayOfWeekFri: 'Friday',
      fromTimeFri: '',
      toTimeFri: '',
    },
    {
      dayOfWeekSat: 'Saturday',
      fromTimeSat: '',
      toTimeSat: '',
    },
  ],
  accomodations: [''],
  serviceGroup: '',
  owner: { userId: '', name: '' },
  platformType: '',
  nfcMasters: [''],
}

const DefaultLocation = { lat: '', lng: '' }
const ViewStation = (): JSX.Element => {
  const [defaultLocation, setDefaultLocation] = useState<any>(DefaultLocation)
  const [zoom, setZoom] = useState(DefaultZoom)
  const [visible, setVisible] = useState(false)
  const [changeComponent, setChangeComponent] = useState(true)
  const [stationList, setStationList] = useState<any>([])
  const [loadingSpin, setLoadingSpin] = useState(true)
  const [paramToDelete, setParamToDelete] = useState<any>(null)
  const [current, setCurrent] = useState(1)
  const [totalDocs, setTotalDocs] = useState(1)
  const [limitDocs, setLimitDocs] = useState(0)
  const [isModalDelete, setIsModalDelete] = useState(false)
  const [dataStation, setDataStation] = useState(null)
  const [photoStation, setPhotoStation] = useState<string[]>([])
  const Auth = useContext(AuthContext)

  useEffect(() => {
    getDataFromApi(current)
  }, [])

  const getDataFromApi = (page: number) => {
    setLoadingSpin(true)
    getEvStationList(Auth, { page })
      .then((res: any) => {
        const { docs, totalDocs, limit } = res.data
        const station = docs
        setTotalDocs(totalDocs)
        setLimitDocs(limit)
        setStationList(station)
        setLoadingSpin(false)
      })
      .catch((error) => {
        Notification(`${error}`, 'error')
      })
  }

  const handleChangePagination = (page: number) => {
    setCurrent(page)
    getDataFromApi(page)
  }

  function handleChangeZoom(newZoom: number) {
    setZoom(newZoom)
  }

  const handleOkDelete = async () => {
    setIsModalDelete(false)
    await deleteEvStation(Auth, paramToDelete)
      .then((res: any) => {
        Notification('Delete Successfully', 'success')
      })
      .catch((error: any) => {
        Notification(`${error}`, 'error')
      })
    await setLoadingSpin(true)
    await getDataFromApi(current)
  }

  const handleCancelDelete = () => {
    setIsModalDelete(false)
  }

  const handleShowData = (value: any) => {
    setVisible(true)
    mockData.accomodations = ['']
    mockData.nfcMasters = ['']
    const {
      stationName,
      chargers,
      location,
      serviceTime,
      accomodations,
      serviceGroup,
      owner,
      platformType,
      stationStatus,
      photos,
      nfcMasters,
    } = value
    setPhotoStation(photos)
    mockData.accomodations = ['']
    accomodations.map((item: never) => {
      const { name } = item
      mockData.accomodations.push(name)
    })
    const { name, latitude, longitude } = location
    mockData.stationName = stationName
    mockData.chargers = chargers
    mockData.locationName = name
    mockData.latitude = latitude
    mockData.longitude = longitude
    setDefaultLocation({ lat: mockData.latitude, lng: mockData.longitude })
    mockData.serviceTime[0].fromTimeSun = serviceTime[0]?.fromTime
    mockData.serviceTime[0].toTimeSun = serviceTime[0]?.toTime
    mockData.serviceTime[1].fromTimeMon = serviceTime[1]?.fromTime
    mockData.serviceTime[1].toTimeMon = serviceTime[1]?.toTime
    mockData.serviceTime[2].fromTimeTue = serviceTime[2]?.fromTime
    mockData.serviceTime[2].toTimeTue = serviceTime[2]?.toTime
    mockData.serviceTime[3].fromTimeWed = serviceTime[3]?.fromTime
    mockData.serviceTime[3].toTimeWed = serviceTime[3]?.toTime
    mockData.serviceTime[4].fromTimeThu = serviceTime[4]?.fromTime
    mockData.serviceTime[4].toTimeThu = serviceTime[4]?.toTime
    mockData.serviceTime[5].fromTimeFri = serviceTime[5]?.fromTime
    mockData.serviceTime[5].toTimeFri = serviceTime[5]?.toTime
    mockData.serviceTime[6].fromTimeSat = serviceTime[6]?.fromTime
    mockData.serviceTime[6].toTimeSat = serviceTime[6]?.toTime
    mockData.serviceGroup = serviceGroup
    mockData.stationStatus = stationStatus
    mockData.owner.userId = owner.userId
    mockData.owner.name = owner.name
    mockData.platformType = platformType
    mockData.nfcMasters = nfcMasters
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: 'stationId',
    },
    {
      title: 'Name',
      dataIndex: 'stationName',
      key: 'stationName',
    },
    {
      title: 'Status',
      dataIndex: 'stationStatus',
      key: 'stationStatus',
    },
    {
      title: 'Platform',
      dataIndex: 'platformType',
      key: 'platformType',
    },
    {
      title: 'Service Group',
      key: 'serviceGroup',
      dataIndex: 'serviceGroup',
    },
    {
      title: 'Action',
      key: '_id',
      render: (item: any) => (
        <Space size="middle">
          <ViewButton shape="round" onClick={() => handleShowData(item)} key={item} value={item}>
            <EyeOutlined /> View
          </ViewButton>
          {/* <EditButton shape="round" onClick={() => handleChangePage(item)} key={item} value={item}>
            <EditOutlined />
            Edit
          </EditButton>
          <DeleteButton
            shape="round"
            danger
            onClick={() => showModalDelete(item)}
            key={item}
            value={item}
          >
            <DeleteOutlined />
            Delete
          </DeleteButton> */}
        </Space>
      ),
    },
  ]

  return (
    <>
      {changeComponent ? (
        <>
          <Row>
            <Title level={1}>Station List</Title>
            {/* <ButtonGlobal
                shape="round"
                style={{ top: 11, left: 10 }}
                onClick={handleChangePageAdd}
              >
                add station
              </ButtonGlobal> */}
            <Title level={4} style={{ position: 'absolute', right: '105px' }}>
              Total Station : {loadingSpin !== false ? loadingSpin : totalDocs}
            </Title>
          </Row>
          <hr style={{ marginBottom: 20 }} />
          <Table
            columns={columns}
            dataSource={stationList}
            loading={loadingSpin}
            pagination={{
              pageSize: limitDocs,
              current: current,
              total: totalDocs,
              onChange: handleChangePagination,
            }}
          />
          <Modal title="Confirm to Delete" visible={isModalDelete} closable={false} footer={null}>
            <h2>
              <DeleteTwoTone /> Delete Station?...
            </h2>
            <div style={{ position: 'relative', bottom: 0, left: '20rem' }}>
              <ViewButton
                shape="round"
                style={{ width: '80px', left: '-4px' }}
                onClick={handleOkDelete}
              >
                Ok
              </ViewButton>
              <DeleteButton shape="round" key="close" onClick={handleCancelDelete}>
                Cancel
              </DeleteButton>
            </div>
          </Modal>
        </>
      ) : (
        <CreateStation dataStation={dataStation} />
      )}
      <Modal
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <Descriptions title="Station Detail" bordered labelStyle={{ fontWeight: 'bolder' }}>
          <Descriptions.Item label="Name">{mockData.stationName}</Descriptions.Item>
          <Descriptions.Item label="Platform">{mockData.platformType}</Descriptions.Item>
          <Descriptions.Item label="Status">{mockData.stationStatus}</Descriptions.Item>
          <Descriptions.Item label="Chargers Code">{`${mockData.chargers}`}</Descriptions.Item>
          <Descriptions.Item label="Accomodations">
            <Row>
              <Col md={24} sm={24} xs={24}>
                <p style={{ padding: 1 }}>{`${mockData.accomodations}`}</p>
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="Service Group">{mockData.serviceGroup}</Descriptions.Item>
          <Descriptions.Item label="Owner">
            <Row>
              <Col md={24} sm={24} xs={24}>
                <p style={{ padding: 5 }}>ID: {mockData.owner.userId}</p>
                <p style={{ padding: 5 }}>NAME: {mockData.owner.name}</p>
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item
            label="Office Hours"
            span={2}
            style={{ justifyItems: 'center', alignItems: 'center' }}
          >
            <Row>
              <Col span={7}>
                <TitleTime>Date</TitleTime>
                <p>Sunday</p>
              </Col>
              <Col span={17}>
                <TitleTime>Open - Close</TitleTime>
                {mockData.serviceTime[0].fromTimeSun} - {mockData.serviceTime[0].toTimeSun}
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <p>Monday</p>
              </Col>
              <Col span={17}>
                {mockData.serviceTime[1].fromTimeMon} - {mockData.serviceTime[1].toTimeMon}
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <p>Tuesday</p>
              </Col>
              <Col span={17}>
                {mockData.serviceTime[2].fromTimeTue} - {mockData.serviceTime[2].toTimeTue}
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <p>Wednesday</p>
              </Col>
              <Col span={17}>
                {mockData.serviceTime[3].fromTimeWed} - {mockData.serviceTime[3].toTimeWed}
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <p>Thursday</p>
              </Col>
              <Col span={17}>
                {mockData.serviceTime[4].fromTimeThu} - {mockData.serviceTime[4].toTimeThu}
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <p>Friday</p>
              </Col>
              <Col span={17}>
                {mockData.serviceTime[5].fromTimeFri} - {mockData.serviceTime[5].toTimeFri}
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <p>Saturday</p>
              </Col>
              <Col span={17}>
                {mockData.serviceTime[6].fromTimeSat} - {mockData.serviceTime[6].toTimeSat}
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="Location" span={3}>
            <p>name: {mockData.locationName}</p>
            <Row>
              <p style={{ marginRight: 10 }}> latitude: {mockData.latitude}</p>
              <p> longitude: {mockData.longitude}</p>
            </Row>
            <MapPicker
              style={{ marginTop: 10, marginBottom: 30 }}
              defaultLocation={defaultLocation}
              zoom={15}
              onChangeZoom={handleChangeZoom}
              apiKey={googleMaps?.API_KEY}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Photos" span={3}>
            {photoStation.length === 0 ? (
              <p style={{ color: 'red' }}>This station has no photo</p>
            ) : (
              <Row gutter={16}>
                <Col md={24} sm={24} xs={24}>
                  {photoStation?.map((item: any) => (
                    <Image width={150} src={item} style={{ padding: 10 }} />
                  ))}
                </Col>
              </Row>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Nfc Masters" span={3}>
            <Row>
              {mockData.nfcMasters.map((item: string, index: number) => (
                <Col md={3} sm={3} xs={3}>
                  <div style={{ padding: 1 }} key={index}>
                    <Row>
                      <p style={{ fontWeight: 'bold' }}>{index + 1}.</p>
                      <p style={{ paddingLeft: 3 }}>{`${item}`}</p>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  )
}

export default ViewStation
