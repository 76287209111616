import axios from 'axios'
import config from '../../config'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export enum ReservationType {
  FLEET = 'fleet',
  CORPORATE = 'corporate',
}

export enum RequestAction {
  accept = 'accept',
  reject = 'reject',
}

export enum ControlAction {
  lock = 'lock',
  unlock = 'unlock',
}

interface IUserDetail {
  userId: number
  userName: string
}

export interface INoteDocument {
  detail: string
  _id: string
  noteType: 'OTHER' | 'REFUND'
  refundAmount: number
  user: IUserDetail
  reservationId: number
  createdAt: string
  updatedAt: string
}

export interface IAddNoteDocument {
  detail: string
  noteType: 'OTHER' | 'REFUND'
  refundAmount?: number
  reservationId: number
}

export interface IEditNoteDocument {
  detail?: string
  _id: string
  noteType?: 'OTHER' | 'REFUND'
  refundAmount?: number
}

interface GetReportCSVFilter {
  reservationNo?: number
  mobile?: string
  actualStartTime?: moment.Moment
  actualStopTime?: moment.Moment
  status?: 'REQUEST' | 'RESERVE' | 'ON_GOING' | 'FINISH' | 'COMPLETE' | 'REJECT' | 'CANCEL'
  stationId?: number
  refunded?: boolean
}

export async function getReservationList(
  authContext: IAuthContext,
  filterSearch: any,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    const page = filterSearch?.page ? filterSearch.page : 1
    const pageSize = filterSearch?.pageSize ? filterSearch.pageSize : 50
    const refunded = filterSearch?.refunded ? 1 : undefined
    axios
      .get(`${restfulService.URL}/ev-admin/reservation`, {
        headers: {
          authorization: userToken,
        },
        params: {
          page,
          limit: pageSize,
          _id: filterSearch.reservationNo,
          start_time:
            (filterSearch.start_time && filterSearch.start_time.format('YYYY-MM-DD')) || undefined,
          status: filterSearch.status || undefined,
          stop_time:
            (filterSearch?.stop_time && filterSearch.stop_time.format('YYYY-MM-DD')) || undefined,
          station_id: filterSearch.station_id || undefined,
          'customer.mobile': filterSearch.mobile || undefined,
          refunded: refunded || undefined,
          query: filterSearch.query || undefined,
          reservation_id: filterSearch.reservation_id || undefined,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getReportCSV(
  authContext: IAuthContext,
  filterSearch: GetReportCSVFilter = {},
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    const refunded = filterSearch?.refunded ? 1 : undefined
    axios
      .get(`${restfulService.URL}/ev-admin/reservation/csv`, {
        timeout: 180000,
        headers: {
          authorization: `${userToken}`,
        },
        params: {
          _id: filterSearch.reservationNo,
          startTime:
            (filterSearch.actualStartTime && filterSearch.actualStartTime.format('YYYY-MM-DD')) ||
            undefined,
          status: filterSearch.status || undefined,
          stopTime:
            (filterSearch?.actualStopTime && filterSearch.actualStopTime.format('YYYY-MM-DD')) ||
            undefined,
          stationId: filterSearch.stationId || undefined,
          'customer.mobile': filterSearch.mobile || undefined,
          refunded: refunded || undefined,
          buffer_size: 3000,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function getNoteList(authContext: IAuthContext, filterSearch: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    const page = filterSearch?.page ? filterSearch.page : 1
    const pageSize = filterSearch?.pageSize ? filterSearch.pageSize : 50
    axios
      .get(`${restfulService.URL}/cms/evcharger/note/list`, {
        headers: {
          authorization: userToken,
        },
        params: {
          page,
          limit: pageSize,
          reservationId: filterSearch.reservationId,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function createNote(authContext: IAuthContext, body: INoteDocument): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .post(`${restfulService.URL}/cms/evcharger/note`, body, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function deleteNote(authContext: IAuthContext, body: { _id: string }): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .delete(`${restfulService.URL}/cms/evcharger/note`, {
        headers: {
          authorization: userToken,
        },
        data: body,
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function updateNote(authContext: IAuthContext, body: IEditNoteDocument): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = authContext?.userLoggedIn?.userToken
    axios
      .put(`${restfulService.URL}/cms/evcharger/note`, body, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
