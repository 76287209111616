import { Space, Table, Tag } from 'antd'
import React from 'react'
// import { manageRequestReservation, RequestAction } from '../../services/evreservation'
import { AuthContext } from './../../contexts/Auth'

interface IReservationStatus {
  reservationNo: number
  status: string
}

interface IReceipt {
  receiptNo: number | null
  receiptFile: string
}

interface IReservationTime {
  actualStartTime: string
  actualStopTime: string
  actualReserveMinute: number
}

interface IUsageData {
  startTime: string | null
  stopTime: string | null
  chargedTime: number | null
  chargedKwh: number | null
}

interface ICustomer {
  userId: number | null
  nfcId: string | null
  cardType: string
  mobile: string | null
}

interface INote {
  attachFiles: string[]
  refundAmount: number
  user: {
    userId: number
    userName: string
  }
  reservationId: number
  noteType: string
  detail: string
}

const columns = [
  {
    title: 'Reservation No.',
    dataIndex: 'reservationStatus',
    render: (reservationStatus: IReservationStatus) => renderReservationStatus(reservationStatus),
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    render: (record: any) => renderNotes(record),
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    render: (customer: ICustomer) => renderCustomer(customer),
  },
  {
    title: 'Receipt No.',
    dataIndex: 'receipt',
    render: (receipt: IReceipt) => renderReceipt(receipt),
  },
  {
    title: 'Station',
    dataIndex: 'station',
  },
  {
    title: 'Charger',
    dataIndex: 'charger',
    render: (charger: any) => (
      <p>
        {charger?.chargerCode}
        <br />({charger?.deviceType})
      </p>
    ),
  },
  {
    title: 'Reservation Time',
    dataIndex: 'reserveTime',
    render: (reserveTime: IReservationTime) => renderReservationTime(reserveTime),
  },
  {
    title: 'Usage Time',
    dataIndex: 'usageData',
    render: (usageData: IUsageData) => renderUsageTime(usageData),
  },
  {
    title: 'Energy (kWh)',
    dataIndex: 'usageData',
    render: (usageData: IUsageData) => <p>{usageData?.chargedKwh}</p>,
  },
  {
    title: 'Payment',
    dataIndex: 'revenue',
    render: (revenue: any) => {
      return (
        <>
          <p>
            <strong>Payment:</strong> {revenue?.paymentType}
          </p>
          <p>
            <strong>Paid:</strong> {revenue?.revenue ?? '-'} ฿
          </p>
          {revenue?.totalRefunded > 0 && (
            <p>
              <strong>Refunded:</strong> {revenue?.totalRefunded} ฿
            </p>
          )}
        </>
      )
    },
  },
  {
    title: 'Channel',
    dataIndex: 'channel',
  },
]

const renderReservationStatus = (data: IReservationStatus) => {
  let color = 'default'
  const { status, reservationNo } = data
  if (status === 'RESERVE' || status === 'REQUEST') {
    color = 'blue'
  }
  if (status === 'CANCEL' || status === 'REJECT') {
    color = 'red'
  }
  if (status === 'DRIVE') {
    color = 'green'
  }

  const StatusLabel = (
    <Tag color={color} style={{ width: '70px', textAlign: 'center' }}>
      {data?.status}
    </Tag>
  )

  return (
    <>
      <Space>
        {reservationNo}
        {StatusLabel}
      </Space>
    </>
  )
}

function renderNotes(notes: INote[]) {
  return (notes || []).map((note) => (
    <div style={{ border: '1px solid #00000050', padding: '5px' }}>
      <p>
        <strong>user:</strong> {note.user?.userName}
      </p>
      <p>
        <strong>noteType:</strong> {note.noteType}
      </p>
      {note.refundAmount && (
        <p>
          <strong>refund:</strong> {note.refundAmount}
        </p>
      )}
      <p>
        <strong>detail:</strong> {note.detail}
      </p>
    </div>
  ))
}

const renderCustomer = (data: ICustomer) => {
  const { userId, nfcId, cardType, mobile } = data
  return (
    <>
      <p>
        <strong>userId:</strong> {userId}
      </p>
      <p>
        <strong>nfcId:</strong> {nfcId}
      </p>
      <p>
        <strong>cardType:</strong> {cardType}
      </p>
      <p>
        <strong>mobile:</strong> {mobile}
      </p>
    </>
  )
}

const renderReservationTime = (data: IReservationTime) => {
  return (
    <>
      <p>
        <strong>From:</strong> {data?.actualStartTime}
      </p>
      <p>
        <strong>To:</strong> {data?.actualStopTime}
      </p>
      <p>({data?.actualReserveMinute} min)</p>
    </>
  )
}

const renderReceipt = (data: IReceipt) => {
  if (data?.receiptFile) {
    return (
      <>
        <p>
          <a href={data?.receiptFile}>{data?.receiptNo}</a>
        </p>
      </>
    )
  } else {
    return (
      <>
        <p>{data?.receiptNo}</p>
      </>
    )
  }
}

const renderUsageTime = (data: IUsageData) => {
  return (
    <>
      <p>
        <strong>From:</strong> {data?.startTime}
      </p>
      <p>
        <strong>To:</strong> {data?.stopTime}
      </p>
      <p>({data?.chargedTime} min)</p>
    </>
  )
}

interface IReservationListProps {
  dataSource: any
  getReservationDetail: any
  pagination: any
  onTableChange: any
  loading: boolean
}

class ReservationList extends React.Component<IReservationListProps, unknown> {
  dataSource
  getReservationDetail
  constructor(props: IReservationListProps) {
    super(props)
    this.dataSource = this.props.dataSource
    this.getReservationDetail = this.props.getReservationDetail
  }
  static contextType = AuthContext
  render() {
    return (
      <Table
        // scroll={{ y: '100%', x: true }}
        size="small"
        columns={columns}
        dataSource={this.props.dataSource}
        pagination={this.props.pagination}
        onChange={this.props.onTableChange}
        loading={this.props.loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              this.getReservationDetail(record.key)
            }, // click rows
          }
        }}
      />
    )
  }
}

export default ReservationList
