import ViewCharger from '../containers/EvCharger/viewCharger'
import EvReservation from '../containers/EvReservation'
import ViewStation from '../containers/EvStation/viewStation'
import ViewPricing from '../containers/Pricing'

export type RouteItem = {
  title: string
  path: string
  requireAuth: boolean
  container: any
}

const routes: RouteItem[] = [
  { title: 'EvCharger', path: '/charger', requireAuth: true, container: ViewCharger },
  { title: 'EvStation', path: '/station', requireAuth: true, container: ViewStation },
  {
    title: 'EvCharger(Pricing)',
    path: '/charger/pricing',
    requireAuth: true,
    container: ViewPricing,
  },
  {
    title: 'Ev Reservation',
    path: '/',
    requireAuth: true,
    container: EvReservation,
  },
]

export default routes
