import React from 'react'
import { Route, Switch } from 'react-router-dom'
import routeconfig, { RouteItem } from '../../config/route'
import Login from '../../containers/Login'
import Logout from '../../containers/Logout'
import AuthenticatedPage from '../AuthenticatedPage'
import Page from '../Page/Page'

class Router extends React.Component {
  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        {routeconfig.map((item: RouteItem) => {
          if (item.requireAuth) {
            return (
              <Route exact path={item.path} key={item.title}>
                <AuthenticatedPage>
                  <Page>
                    <item.container />
                  </Page>
                </AuthenticatedPage>
              </Route>
            )
          } else {
            return (
              <Route exact path={item.path} key={item.title}>
                <Page>
                  <item.container />
                </Page>
              </Route>
            )
          }
        })}
      </Switch>
    )
  }
}

export default Router
