import { DeleteTwoTone, EyeOutlined } from '@ant-design/icons'
import { Descriptions, Modal, Row, Space, Table, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Notification from '../../components/Notification'
import { DeleteButton, ViewButton } from '../../components/StyledComponent'
import { AuthContext } from '../../contexts/Auth'
import { IPostPicing, deletePricing, getPricingList } from '../../services/pricing'
import Pricing from './addPricing'

const { Title } = Typography

export const mapPricingData = (data: IPostPicing[]) => {
  const chargerResult = data.map((item) => {
    const {
      priceCode,
      priceDescription,
      basePrice,
      electricPrice,
      hourPrice,
      penaltyFeePerHour,
      _id,
    } = item || {}
    const { serviceFee } = basePrice || {}
    const { serviceFee: hourServiceFee, parkingFee } = hourPrice || {}
    return {
      _id,
      priceCode,
      priceDescription,
      penaltyFeePerHour,
      hourPrice,
      electricPrice,
      serviceFee,
      hourServiceFee,
      parkingFee,
    }
  })
  return chargerResult
}
const mockData = {
  priceCode: '',
  priceDescription: '',
  baseServiceFee: '',
  hourServiceFee: '',
  parkingFee: '',
  electricPrice: '',
  penaltyFee: '',
}

const ViewPricing = (): JSX.Element => {
  const [changePagePricing, setChangePagePricing] = useState(true)
  const [pricingList, setPricingList] = useState<any>([])
  const [loadingPricing, setLoadingPricing] = useState(true)
  const [dataPricingUpdate, setDataPricingUpdate] = useState<any>([])
  const [paramToDelete, setParamToDelete] = useState<any>(null)
  const [visible, setVisible] = useState(false)
  const [current, setCurrent] = useState(1)
  const [totalDocs, setTotalDocs] = useState(1)
  const [limitDocs, setLimitDocs] = useState(0)
  const [isModalDelete, setIsModalDelete] = useState(false)
  const Auth = useContext<any>(AuthContext)
  const history = useHistory()

  useEffect(() => {
    getDataFromApi(current)
  }, [])

  const getDataFromApi = (page: number) => {
    setLoadingPricing(true)
    getPricingList(Auth, page)
      .then((result: any) => {
        const { docs, totalDocs, limit } = result?.data
        const pricing = mapPricingData(docs)
        setTotalDocs(totalDocs)
        setLimitDocs(limit)
        setPricingList(pricing)
        setLoadingPricing(false)
      })
      .catch((error) => {
        Notification(`${error}`, 'error')
      })
  }
  const mapPricingDataId = (data: any) => {
    const { _id } = data
    setParamToDelete(_id)
    return _id
  }
  const handleChangePagination = (page: number) => {
    setCurrent(page)
    getDataFromApi(page)
  }
  const handleOkDelete = async () => {
    setIsModalDelete(false)
    await deletePricing(Auth, paramToDelete)
      .then((res: any) => {
        Notification('Delete Successfully', 'success')
        getPricingList
      })
      .catch((error: any) => {
        Notification(`${error}`, 'error')
      })
    await setLoadingPricing(true)
    await getDataFromApi(current)
  }
  const handleCancelDelete = () => {
    setIsModalDelete(false)
  }

  const handleShowData = (value: any) => {
    setVisible(true)
    const {
      priceCode,
      serviceFee,
      hourServiceFee,
      priceDescription,
      electricPrice,
      hourPrice,
      penaltyFeePerHour,
    } = value || {}
    const { parkingFee } = hourPrice || {}
    mockData.priceCode = priceCode
    mockData.priceDescription = priceDescription ? priceDescription : 'not specified'
    mockData.baseServiceFee = serviceFee
    mockData.hourServiceFee = hourServiceFee
    mockData.parkingFee = parkingFee
    mockData.electricPrice = electricPrice
    mockData.penaltyFee = penaltyFeePerHour
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: 'chargerId',
    },
    {
      title: 'Price Code',
      dataIndex: 'priceCode',
      key: 'priceCode',
    },
    {
      title: 'Description',
      dataIndex: 'priceDescription',
      key: 'priceDescription',
      render: (item: any, index: any) => (
        <>
          <p>{item !== null ? item : 'not specified'}</p>
        </>
      ),
    },
    {
      title: 'ElectricPrice',
      dataIndex: 'electricPrice',
      key: 'electricPrice',
    },
    {
      title: 'PenaltyFee',
      dataIndex: 'penaltyFeePerHour',
      key: 'penaltyFeePerHour',
    },
    {
      title: 'Action',
      key: '_id',
      render: (item: any, index: any) => (
        <>
          <Space size="middle">
            <ViewButton shape="round" onClick={() => handleShowData(item)} key={item} value={item}>
              <EyeOutlined /> View
            </ViewButton>
            {/* <EditButton
              shape="round"
              onClick={() => handleChangePage(item)}
              key={item}
              value={item}
            >
              <EditOutlined />
              Edit
            </EditButton>
            <DeleteButton
              shape="round"
              danger
              onClick={() => showModalDelete(item)}
              key={item}
              value={item}
            >
              <DeleteOutlined />
              Delete
            </DeleteButton> */}
          </Space>
        </>
      ),
    },
  ]

  return (
    <>
      {changePagePricing ? (
        <>
          <Row>
            <Title level={1}>View Pricing</Title>
            {/* <ButtonGlobal
                shape="round"
                type="primary"
                style={{ top: 11, left: 10 }}
                onClick={handleChangePage}
              >
                Add Pricing
              </ButtonGlobal> */}
            <Title level={4} style={{ position: 'absolute', right: '105px' }}>
              Total PriceId : {loadingPricing !== false ? loadingPricing : totalDocs}
            </Title>
          </Row>
          <hr style={{ marginBottom: 20 }} />
          <Table
            columns={columns}
            dataSource={pricingList}
            loading={loadingPricing}
            pagination={{
              pageSize: limitDocs,
              current: current,
              total: totalDocs,
              onChange: handleChangePagination,
            }}
          />
        </>
      ) : (
        <Pricing dataPricingUpdate={dataPricingUpdate} />
      )}
      <Modal title="Confirm to Delete" visible={isModalDelete} closable={false} footer={null}>
        <h2>
          <DeleteTwoTone /> Delete Pricing?...
        </h2>
        <div style={{ position: 'relative', bottom: 0, left: '20rem' }}>
          <ViewButton
            shape="round"
            style={{ width: '80px', left: '-4px' }}
            onClick={handleOkDelete}
          >
            Ok
          </ViewButton>
          <DeleteButton shape="round" key="close" onClick={handleCancelDelete}>
            Cancel
          </DeleteButton>
        </div>
      </Modal>
      <Modal
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <Descriptions title="Price Info" layout="vertical" bordered>
          <Descriptions.Item label="Price Code">{mockData.priceCode}</Descriptions.Item>
          <Descriptions.Item label="Price Description">
            {mockData.priceDescription}
          </Descriptions.Item>
          <Descriptions.Item label="electricPrice">
            {mockData.electricPrice} ฿ / kWh
          </Descriptions.Item>
          <Descriptions.Item label="PenaltyFee Per Hour">
            {mockData.penaltyFee} ฿ / kWh
          </Descriptions.Item>
          <Descriptions.Item label="Base Price">
            <b>serviceFee:</b> {mockData.baseServiceFee} ฿ / hour
          </Descriptions.Item>
          <Descriptions.Item label="hourPrice">
            <b>serviceFee:</b> {mockData.hourServiceFee} ฿ / hour <br /> <b>parkingFee:</b>{' '}
            {mockData.parkingFee} ฿ / hour
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  )
}
export default ViewPricing
