import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  TimePicker,
  Transfer,
  Typography,
  Upload,
  message,
} from 'antd'
import moment from 'moment'
import * as R from 'ramda'
import { useContext, useEffect, useState } from 'react'
import MapPicker from 'react-google-map-picker'
import styled from 'styled-components'
import Notification from '../../components/Notification'
import { ButtonGlobal, ViewButton } from '../../components/StyledComponent'
import { default as config, default as configs } from '../../config'
import { AuthContext } from './../../contexts/Auth'
import { getEvCharger } from './../../services/evcharger'
import {
  ICreateStation,
  createEvStation,
  getAccomoDations,
  updateEvStation,
} from './../../services/evstation'
import ViewStation from './viewStation'

const { uploadService } = config

interface IChargerData {
  chargerCode: string
  _id: number
  deviceInfo: []
  stationId: number
  chargerStatus: string
  deviceType: string
  deviceProtocol: string
  serviceGroup: string
  connectors: []
  photos: string[]
}

interface IAccomodationData {
  name: string
  _id: any
}

const { googleMaps } = configs
const { Option } = Select
const { Title } = Typography

const TitleTime = styled.p`
  font-size: 13px;
  margin-bottom: 10px;
  color: #1890ff;
  font-weight: 600;
`
const TextDays = styled.p`
  margin-bottom: 20px;
`

const DefaultZoom = 10

function mapAccomadationData(data: IAccomodationData[]) {
  const accomodationResult = data.map((item) => {
    const { name, _id } = item
    return {
      name,
      _id,
    }
  })
  return accomodationResult
}

export function mapChargerData(data: IChargerData[]) {
  const chargerResult = data.map((item) => {
    const key = item._id
    const {
      chargerCode,
      deviceInfo,
      stationId,
      _id,
      chargerStatus,
      deviceType,
      deviceProtocol,
      serviceGroup,
      connectors,
      photos,
    } = item
    return {
      chargerCode,
      key,
      deviceInfo,
      stationId,
      _id,
      chargerStatus,
      deviceType,
      deviceProtocol,
      serviceGroup,
      connectors,
      photos,
    }
  })
  return chargerResult
}

const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const defaultServiceTimes = [
  { dayOfWeek: 'Sunday', fromTime: '00:00', toTime: '23:59' },
  { dayOfWeek: 'Monday', fromTime: '00:00', toTime: '23:59' },
  { dayOfWeek: 'Tuesday', fromTime: '00:00', toTime: '23:59' },
  { dayOfWeek: 'Wednesday', fromTime: '00:00', toTime: '23:59' },
  { dayOfWeek: 'Thursday', fromTime: '00:00', toTime: '23:59' },
  { dayOfWeek: 'Friday', fromTime: '00:00', toTime: '23:59' },
  { dayOfWeek: 'Saturday', fromTime: '00:00', toTime: '23:59' },
]

const CreateStation = (props: any): JSX.Element => {
  const { dataStation } = props || {}
  const {
    _id,
    location,
    chargers,
    stationName,
    accomodations,
    owner,
    platformType,
    serviceGroup,
    serviceTime,
    photos,
    nfcMasters,
  } = dataStation || {}
  const mockEditNfcMasters = nfcMasters?.map((item: string) => {
    return { nfcMasters: item }
  })
  const { userId, name: Ownername } = owner || {}
  const { name, latitude, longitude } = location || {}
  const DefaultLocation: any = !location
    ? {
        lat: 13.756199928708677,
        lng: 100.53259491920471,
      }
    : { lat: latitude, lng: longitude }
  const [defaultLocation, setDefaultLocation] = useState<any>(DefaultLocation)
  const [locationSet, setLocationSet] = useState<any>(defaultLocation)
  const [zoom, setZoom] = useState(DefaultZoom)
  const [serviceTimes, setServiceTimes] = useState(!serviceTime ? defaultServiceTimes : serviceTime)
  const [changeComponent, setChangeComponent] = useState(false) /// Disable add station
  const [loadingCharger, setLoadingCharger] = useState(false)
  const [chargerList, setChargerList] = useState<any>([])
  const [accomodationsSelect, setAccomoDationsSelect] = useState<any>(
    !accomodations ? [] : accomodations,
  )
  const [accomodationsList, setAccomoDationsList] = useState<any>([])
  const [propsCharger, setPropsCharger] = useState<any>(chargers)
  const Auth = useContext(AuthContext)
  const [targetKeys, setTargetKeys] = useState<any>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [fileList, setFileList] = useState<any>(!photos ? [] : photos)
  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
  }

  useEffect(() => {
    getEvCharger().then((res: any) => {
      const { docs } = res.data
      const charger = mapChargerData(docs)
      setChargerList(charger)
      setLoadingCharger(true)
    })
    getAccomoDations(Auth)
      .then((res: any) => {
        const { docs } = res.data
        const accomodations = mapAccomadationData(docs)
        setAccomoDationsList(accomodations)
      })
      .catch((error) => {
        Notification(`${error}`, 'error')
      })
  }, [])

  const mapAccomoDation = accomodationsSelect?.map((item: any) => {
    const { _id } = item
    return _id
  })

  const onChange = (nextTargetKeys: any) => {
    setTargetKeys(nextTargetKeys)
    setPropsCharger(nextTargetKeys)
  }
  function onChangeAcco(checkedValues: any) {
    setAccomoDationsSelect(checkedValues)
  }

  const onSelectChange = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  function handleChangeLocation(lat: number, lng: number) {
    setLocationSet({ lat: lat, lng: lng })
  }
  function handleChangeZoom(newZoom: number) {
    setZoom(newZoom)
  }
  function handleChangeTime(time: any, dayOfWeek: number) {
    const editServiceTime = R.clone(serviceTimes)
    const fromTime = time[0]?.format('HH:mm')
    const toTime = time[1]?.format('HH:mm')
    editServiceTime[dayOfWeek] = { dayOfWeek, fromTime, toTime }
    setServiceTimes(editServiceTime)
  }
  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation })
    setZoom(DefaultZoom)
    setLocationSet(DefaultLocation)
  }
  function handleChangePage() {
    setChangeComponent(false)
  }

  function mapUploadData(data: any[]) {
    const mappedResult = data?.map((item) => {
      if (item?.response) {
        const { fileUrl } = item.response
        return fileUrl
      }
    })
    return mappedResult
  }
  const mapFileList = mapUploadData(fileList)
  const filterPhoto = mapFileList?.filter(function (value: string | undefined) {
    return value !== undefined
  })

  const propsUrlPhotos: any[] = []
  if (photos) {
    fileList?.map((item: any) => {
      if (item?.response) {
        const { fileUrl } = item.response
        propsUrlPhotos.push(fileUrl)
      }
      if (item?.url) {
        propsUrlPhotos.push(item?.url)
      }
    })
  }
  propsUrlPhotos?.filter(function (value: string | undefined) {
    return value !== undefined
  })

  const createStation = async (data: ICreateStation) => {
    const photos: string[] = filterPhoto
    const stationStatus = 'AVAILABLE'
    const serviceStatus = 'SERVICE'
    let latLocation = locationSet.lat
    let lngLocation = locationSet.lng
    const {
      stationName,
      serviceGroup,
      platformType,
      location,
      userId,
      name,
      lat,
      long,
      nfcMasters,
    } = data || {}
    const modifyNfcMasters: string[] = nfcMasters?.map((item: string) => {
      const { nfcMasters }: any = item || {}
      return nfcMasters
    })
    if (lat && long) {
      latLocation = lat
      lngLocation = long
    }
    const postdata: ICreateStation = {
      chargers: propsCharger ? propsCharger : targetKeys,
      stationName,
      nfcMasters: modifyNfcMasters ? modifyNfcMasters : [],
      stationStatus,
      serviceStatus,
      location: {
        name: location,
        latitude: latLocation,
        longitude: lngLocation,
      },
      serviceTime: [
        {
          dayOfWeek: '0',
          fromTime: serviceTimes[0].fromTime,
          toTime: serviceTimes[0].toTime,
        },
        { dayOfWeek: '1', fromTime: serviceTimes[1].fromTime, toTime: serviceTimes[1].toTime },
        { dayOfWeek: '2', fromTime: serviceTimes[2].fromTime, toTime: serviceTimes[2].toTime },
        { dayOfWeek: '3', fromTime: serviceTimes[3].fromTime, toTime: serviceTimes[3].toTime },
        { dayOfWeek: '4', fromTime: serviceTimes[4].fromTime, toTime: serviceTimes[4].toTime },
        { dayOfWeek: '5', fromTime: serviceTimes[5].fromTime, toTime: serviceTimes[5].toTime },
        { dayOfWeek: '6', fromTime: serviceTimes[6].fromTime, toTime: serviceTimes[6].toTime },
      ],
      accomodations: accomodationsSelect,
      serviceGroup,
      owner: { userId, name },
      platformType,
      photos: propsUrlPhotos.length > 0 ? propsUrlPhotos : photos,
    }
    !_id
      ? await createEvStation(Auth, postdata).then((res: any) => {
          Notification('Create Successfully', 'success')
        })
      : await updateEvStation(postdata, Auth, _id)
          .then((res: any) => {
            Notification('Update Successfully', 'success')
          })
          .catch((error: any) => {
            Notification(`${error}`, 'error')
          })
    await form.resetFields()
    await setChangeComponent(false)
  }

  return (
    <>
      {changeComponent ? (
        <>
          <Row>
            <Title level={1}>{dataStation == null ? 'Add Station' : 'Edit Station'}</Title>
            <ButtonGlobal
              shape="round"
              type="primary"
              style={{ top: 11, left: 10 }}
              onClick={handleChangePage}
            >
              station list
            </ButtonGlobal>
          </Row>
          <hr style={{ marginBottom: 20 }} />
          <Row>
            <Col span={15} offset={5}>
              <Form
                onFinish={createStation}
                form={form}
                initialValues={{
                  chargers: chargers,
                  stationName: stationName,
                  accomodations: mapAccomoDation,
                  platformType: platformType,
                  serviceGroup: serviceGroup,
                  name: Ownername,
                  userId: userId,
                  location: name,
                  lat: latitude,
                  long: longitude,
                  nfcMasters: mockEditNfcMasters,
                }}
              >
                <Title level={5}>Select Chargers</Title>
                <Col lg={{ offset: 2 }}>
                  <Form.Item name="chargers">
                    {loadingCharger ? (
                      <Transfer
                        dataSource={chargerList}
                        titles={['Charger List', propsCharger ? 'Already Have' : 'Select']}
                        targetKeys={propsCharger ? propsCharger : targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={onChange}
                        onSelectChange={onSelectChange}
                        render={(item: IChargerData) => item.chargerCode}
                        showSelectAll={false}
                        listStyle={{
                          width: 250,
                          height: 250,
                        }}
                      />
                    ) : (
                      <Spin size="large" />
                    )}
                  </Form.Item>
                </Col>
                <Row gutter={16}>
                  <Col md={24} sm={24} xs={24}>
                    <Title level={5}>Station Name</Title>
                    <Form.Item
                      name="stationName"
                      rules={[{ required: true, message: 'Please input station name!' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <SetLocation
                  locationSet={locationSet}
                  handleResetLocation={handleResetLocation}
                  zoom={zoom}
                  handleChangeLocation={handleChangeLocation}
                  handleChangeZoom={handleChangeZoom}
                  dataStation={dataStation}
                />
                <Row gutter={10} style={{ marginBottom: 30 }}>
                  <Title level={5}>Upload Photos</Title>
                  <UploadImg fileList={fileList} setFileList={setFileList} />
                </Row>

                <Row gutter={10}>
                  <ServeiceTime
                    {...props}
                    serviceTimes={serviceTimes}
                    handleChangeTime={handleChangeTime}
                  />
                  <Col md={8} sm={24} xs={24}>
                    <Title level={5}>Accomodations</Title>
                    <Form.Item
                      name="accomodations"
                      rules={[{ required: true, message: 'Please select accomodations!' }]}
                    >
                      <Checkbox.Group onChange={onChangeAcco} defaultValue={[mapAccomoDation]}>
                        <Row>
                          {accomodationsList.map((item: any) => (
                            <Col span={10} key={item._id}>
                              <Checkbox value={item._id} style={{ lineHeight: '32px' }}>
                                {item.name}
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="owner" style={{ marginTop: 10 }}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Title level={5}>Owner</Title>
                      <Form.Item
                        name="name"
                        label="name"
                        rules={[{ required: true, message: 'Please input name!' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="userId" label="userId" style={{ marginTop: 31 }}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Row gutter={16}>
                  <Col md={12} sm={24} xs={24}>
                    <Title level={5}>Platform Type</Title>
                    <Form.Item
                      name="platformType"
                      rules={[{ required: true, message: 'Please select platform type!' }]}
                    >
                      <Select placeholder="select platformType">
                        <Option value="HAUP_PLATFORM">HAUP_PLATFORM</Option>
                        <Option value="EXTERNAL_PLATFORM">EXTERNAL_PLATFORM</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Title level={5}>Service Group</Title>
                    <Form.Item
                      name="serviceGroup"
                      rules={[{ required: true, message: 'Please select service group!' }]}
                    >
                      <Select placeholder="select serviceGroup">
                        <Option value="INTERNAL">INTERNAL</Option>
                        <Option value="PUBLIC">PUBLIC</Option>
                        <Option value="ALL">ALL</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={24} sm={24} xs={24}>
                    <Title level={5}>Nfc Master</Title>
                    <Form.List name="nfcMasters">
                      {(fields, { add, remove }) => (
                        <>
                          {fields?.map(({ key, name, fieldKey, ...restField }) => (
                            <>
                              <Row>
                                <Col md={21} sm={21} xs={21}>
                                  <Form.Item
                                    {...restField}
                                    style={{ marginTop: 7 }}
                                    name={[name, 'nfcMasters']}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Please input nfc master.',
                                      },
                                      {
                                        validator(_, value) {
                                          if (value.length < 11) {
                                            return Promise.resolve()
                                          }
                                          return Promise.reject(
                                            'The digits not must be greater than 10!',
                                          )
                                        },
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Nfc Master"
                                      style={{ width: '99%' }}
                                      type="number"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={2} sm={2} xs={2} style={{ marginTop: 7 }}>
                                  <Popover content="Delete Field">
                                    <MinusCircleOutlined
                                      className="dynamic-delete-button"
                                      onClick={() => remove(name)}
                                      style={{ color: 'red' }}
                                    />
                                  </Popover>
                                </Col>
                              </Row>
                            </>
                          ))}

                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            style={{ marginTop: 5, marginBottom: 20 }}
                          >
                            Add Other
                          </Button>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
                <hr style={{ marginBottom: 20 }} />
                <Row gutter={[16, 50]}>
                  <Col>
                    <ViewButton shape="round" htmlType="submit">
                      {_id ? 'Edit Station' : 'Submit'}
                    </ViewButton>
                  </Col>
                  {dataStation == null ? (
                    <Col>
                      <ButtonGlobal shape="round" htmlType="button" onClick={onReset}>
                        Reset
                      </ButtonGlobal>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      ) : (
        <ViewStation />
      )}
    </>
  )
}

export default CreateStation

const ServeiceTime = (props: any) => {
  const { serviceTimes, handleChangeTime } = props || {}
  return (
    <>
      <Col md={16} sm={24} xs={24}>
        <Title level={5}>Service Time</Title>
        <Col offset={2}>
          <Row>
            <Col span={6}>
              <TitleTime>Date</TitleTime>
              <TextDays>Sunday</TextDays>
              <TextDays>Monday</TextDays>
              <TextDays>Tuesday</TextDays>
              <TextDays>Wednesday</TextDays>
              <TextDays>Thursday</TextDays>
              <TextDays>Friday</TextDays>
              <TextDays>Saturday</TextDays>
            </Col>
            <Col span={17}>
              <TitleTime>Open - Close</TitleTime>
              {serviceTimes.map((item: any, index: number) => (
                <>
                  <TimePicker.RangePicker
                    style={{ marginBottom: 10 }}
                    format="HH:mm"
                    onChange={(value) => handleChangeTime(value, index)}
                    defaultValue={[
                      moment(`${item.fromTime}`, 'HH:mm'),
                      moment(`${item.toTime}`, 'HH:mm'),
                    ]}
                    allowClear={false}
                  />
                </>
              ))}
            </Col>
          </Row>
        </Col>
      </Col>
    </>
  )
}

const SetLocation = (props: any) => {
  const {
    locationSet,
    handleResetLocation,
    zoom,
    handleChangeLocation,
    handleChangeZoom,
    dataStation,
  } = props || {}
  const [inputLocation, setInputLocation] = useState(!dataStation ? false : true)
  const [mapLocation, setMapLocation] = useState(false)

  const handleChangFormMap = (value: any) => {
    if (value === 'drag') {
      setMapLocation(true)
      handleResetLocation
      setInputLocation(false)
    }
    if (value === 'input') {
      setInputLocation(true)
      setMapLocation(false)
    }
  }
  return (
    <>
      <Title level={5}>Location</Title>
      <Row gutter={14}>
        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name="location"
            label="Name"
            rules={[{ required: true, message: 'Please input location name!' }]}
          >
            <Input
              style={{ marginBottom: 5 }}
              placeholder="46 ถนน พญาไท แขวง ถนนพญาไท เขต ราชเทวี กรุงเทพมหานคร 10400"
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item name="setlocation" label="Set Location">
            {!dataStation ? (
              <Select placeholder="select set location" onChange={handleChangFormMap}>
                <Option value="drag">Drag on map</Option>
                <Option value="input">Input</Option>
              </Select>
            ) : (
              <Select
                placeholder="select set location"
                defaultValue="input"
                onChange={handleChangFormMap}
              >
                <Option value="drag">Drag on map</Option>
                <Option value="input">Input</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      {inputLocation && (
        <Row gutter={14}>
          <Col md={12} sm={24} xs={24}>
            <Title level={5}>Latitute: </Title>
            <Form.Item name="lat">
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Title level={5}>Longitute:</Title>
            <Form.Item name="long">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}
      {mapLocation && (
        <>
          <Row gutter={14}>
            <Col md={12} sm={24} xs={24}>
              <Title level={5}>Latitute: </Title>
              {locationSet.lat}
            </Col>
            <Col md={6} sm={24} xs={24}>
              <Title level={5}>Longitute:</Title>
              {locationSet.lng}
            </Col>
            <Col md={6} sm={24} xs={24}>
              <Button onClick={handleResetLocation} style={{ marginTop: 5 }}>
                Reset Location
              </Button>
            </Col>
          </Row>
          <MapPicker
            style={{ marginTop: 10, marginBottom: 30 }}
            defaultLocation={locationSet}
            zoom={zoom}
            onChangeLocation={handleChangeLocation}
            onChangeZoom={handleChangeZoom}
            apiKey={googleMaps?.API_KEY}
          />
        </>
      )}
    </>
  )
}

const UploadImg = (props: any) => {
  const { fileList, setFileList, photos } = props || {}
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const { userLoggedIn }: any = useContext(AuthContext)

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(photos ? photos : file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const handleChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList)
  }
  const beforeUpload = (file: any): boolean => {
    const { type, size, status, name } = file
    const isJpgOrPng = type === 'image/jpeg' || type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = size / 1024 / 1024 < 10
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!')
    }
    if (status === 'uploading') {
      setLoading(true)
    }
    if (status === 'done') {
      if (status === 'done') {
        message.success(`${name} file uploaded successfully`)
        setLoading(false)
      }
    }
    return isJpgOrPng && isLt2M
  }

  const uploadImgService = {
    name: 'fileName',
    action: `${uploadService.URL}/upload`,
    headers: {
      Authorization: userLoggedIn?.userToken,
    },
    data: { project: 'cms', deviceType: 'WEBSITE' },
    beforeUpload,
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <>
      <Upload
        {...uploadImgService}
        listType="picture-card"
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        fileList={fileList}
        accept=".png,.jpg"
      >
        {!fileList ? fileList : uploadButton}
      </Upload>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}
