import { Button, Col, Form, Row, Typography, Input } from 'antd'
import { createPricing, IPostPicing, updatePricing } from '../../services/pricing'
import { AuthContext } from '../../contexts/Auth'
import { useContext, useState } from 'react'
import Notification from '../../components/Notification'
import ViewPricing from '.'
import { ButtonGlobal, SlideInLeftDiv, ViewButton } from '../../components/StyledComponent'

const { Title } = Typography

const Pricing = (props: any): JSX.Element => {
  const { dataPricingUpdate } = props || {}
  const {
    priceDescription,
    electricPrice,
    penaltyFeePerHour,
    _id,
    priceCode,
    hourPrice,
    serviceFee,
  } = dataPricingUpdate || {}
  const { serviceFee: hourServiceFee, parkingFee } = hourPrice || {}
  const [changeComponent, setChangeComponent] = useState(true)
  const Auth = useContext<any>(AuthContext)

  function handleChangePage() {
    setChangeComponent(false)
  }
  const createCharger = async (data: IPostPicing) => {
    const {
      priceCode,
      priceDescription,
      BaseServiceFee,
      hourPrice,
      parkingFee,
      electricPrice,
      penaltyFeePerHour,
    } = data || {}
    const postdata: IPostPicing = {
      priceCode,
      priceDescription: priceDescription ? priceDescription : null,
      basePrice: {
        serviceFee: BaseServiceFee ? BaseServiceFee : 0,
      },
      hourPrice: {
        serviceFee: hourPrice ? hourPrice : 0,
        parkingFee: parkingFee ? parkingFee : 0,
      },
      electricPrice: electricPrice ? electricPrice : 0,
      penaltyFeePerHour: penaltyFeePerHour ? penaltyFeePerHour : 0,
    }
    !_id
      ? await createPricing(postdata, Auth).then((res: any) => {
          Notification('Create Successfully', 'success')
        })
      : await updatePricing(postdata, Auth, _id)
          .then((res: any) => {
            Notification('Update Successfully', 'success')
          })
          .catch((error: any) => {
            Notification(`${error}`, error)
          })
    await setChangeComponent(false)
  }

  return (
    <>
      {changeComponent ? (
        <>
          <SlideInLeftDiv>
            <Row>
              <Title level={1}>{!_id ? 'Add Pricing' : 'Edit Pricing'}</Title>
              <ButtonGlobal shape="round" style={{ top: 11, left: 10 }} onClick={handleChangePage}>
                Pricing list
              </ButtonGlobal>
            </Row>
          </SlideInLeftDiv>
          <hr style={{ marginBottom: 20 }} />
          <Col span={15} offset={5}>
            <SlideInLeftDiv>
              <Form
                onFinish={createCharger}
                initialValues={{
                  priceCode,
                  priceDescription,
                  electricPrice,
                  penaltyFeePerHour,
                  BaseServiceFee: serviceFee,
                  parkingFee,
                  hourPrice: hourServiceFee,
                }}
              >
                <Row gutter={16}>
                  <Col md={12} sm={24} xs={24}>
                    <Title level={5} style={{ bottom: '10px' }}>
                      Price Code and Description
                    </Title>
                    <Form.Item
                      name="priceCode"
                      label="Code"
                      rules={[{ required: true, message: 'Please Enter Price Code!' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name="priceDescription"
                      label="Description"
                      style={{ paddingTop: '30px' }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Title level={5}>Base Price</Title>
                <Row gutter={16}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name="BaseServiceFee"
                      label="ServiceFee"
                      rules={[
                        {
                          validator(_, value) {
                            if (!value || value >= 0) {
                              return Promise.resolve()
                            }
                            return Promise.reject('The value must be greater than 0!')
                          },
                        },
                      ]}
                    >
                      <Input type="number" defaultValue="0" suffix="฿" />
                    </Form.Item>
                  </Col>
                </Row>
                <Title level={5}>Hour Price</Title>
                <Row gutter={16}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name="hourPrice"
                      label="ServiceFee"
                      rules={[
                        {
                          validator(_, value) {
                            if (!value || value >= 0) {
                              return Promise.resolve()
                            }
                            return Promise.reject('The value must be greater than 0!')
                          },
                        },
                      ]}
                    >
                      <Input type="number" defaultValue="0" suffix="฿ / hour" />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name="parkingFee"
                      label="ParkingFee"
                      rules={[
                        {
                          validator(_, value) {
                            if (!value || value >= 0) {
                              return Promise.resolve()
                            }
                            return Promise.reject('The value must be greater than 0!')
                          },
                        },
                      ]}
                    >
                      <Input type="number" defaultValue="0" suffix="฿ / hour" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} sm={24} xs={24}>
                    <Title level={5}>ElectricPrice</Title>
                    <Form.Item
                      name="electricPrice"
                      label="ElectricPrice"
                      rules={[
                        {
                          validator(_, value) {
                            if (!value || value >= 0) {
                              return Promise.resolve()
                            }
                            return Promise.reject('The value must be greater than 0!')
                          },
                        },
                      ]}
                    >
                      <Input type="number" defaultValue="0" suffix="฿ / kWh" />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Title level={5}>PenaltyFee</Title>
                    <Form.Item
                      name="penaltyFeePerHour"
                      label="PenaltyFee"
                      rules={[
                        {
                          validator(_, value) {
                            if (!value || value >= 0) {
                              return Promise.resolve()
                            }
                            return Promise.reject('The value must be greater than 0!')
                          },
                        },
                      ]}
                    >
                      <Input type="number" defaultValue="0" suffix="฿ / kWh" />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{ marginBottom: 20 }} />
                <Row gutter={[16, 50]}>
                  <Col>
                    <ViewButton shape="round" htmlType="submit">
                      {_id ? 'Edit Price' : 'Submit'}
                    </ViewButton>
                  </Col>
                </Row>
              </Form>
            </SlideInLeftDiv>
          </Col>
        </>
      ) : (
        <ViewPricing />
      )}
    </>
  )
}

export default Pricing
