import _axios from 'axios'
import config from '../../config'
import { getUserToken } from '../../contexts/Auth'

const { restfulService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export interface ICreateCharger {
  serviceStatus: any
  controlDevice: any
  deviceInfo: IDeviceInfo
  stationId: number
  chargerCode: string
  chargerStatus: string
  deviceType: string
  serviceGroup: string
  deviceProtocol: string
  connectors: IConnectors
}

interface IDeviceInfo {
  vendor: string
  model: string
  serialNumber: string
  firmwareVersion: string
}

export interface IConnectors {
  connectorSelector: string
  connectorType: IConnectorType
  maxKw: number
  connectorStatus: string
  priceId: number
}

export interface IConnectorType {
  code: string
  name: string
  currentType: string
}

export interface ICreateVehicleBrand {
  _id?: string
  logo?: string
  domesticSale: boolean
  brand: string
}

export interface ICreateVehicleModel {
  year: number
  _id?: string
  brandId: string
  model: string
  connectors: IConnectors
  connectorsList?: any
}

export interface IConnectors {
  connectorType: IConnectorType
  maxKw: number
}

export interface IConnectorType {
  code: string
  name: string
}

function createAxios() {
  return _axios.create({
    baseURL: restfulService.URL || '',
    headers: {
      authorization: getUserToken(),
    },
  })
}

export async function getEvCharger(page?: number): Promise<any> {
  const axios = createAxios()
  return new Promise((resolve, reject) => {
    axios
      .get(`/ev-admin/charger`, {
        params: { page },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
