import { Layout } from 'antd'
import 'antd/dist/antd.less'
import React from 'react'
import { menus } from '../../config/menu'
import { AuthContext } from '../../contexts/Auth'
import '../../static/css/index.less'
import HeaderMenu from '../HeaderMenu/HeaderMenu'
import SideMenu from '../SideMenu/SideMenu'

const { Content, Footer } = Layout

interface AppProps {
  children: React.ReactNode
}

interface AppState {
  collapsed: boolean
  user: any
}

class Page extends React.Component<AppProps, AppState> {
  static contextType = AuthContext
  state = {
    collapsed: false,
    user: {
      userId: null,
      userName: '',
      userToken: null,
    },
  }
  user = {
    userId: null,
    userName: '',
    userToken: null,
  }

  onCollapse = (collapsed: boolean): void => {
    this.setState({ collapsed })
  }

  render(): JSX.Element {
    const { collapsed } = this.state
    return (
      <>
        <Layout style={{ minHeight: '100vh' }}>
          <SideMenu collapsed={collapsed} onCollapse={this.onCollapse} menus={menus} />
          <Layout className="site-layout" style={{ height: '100vh' }}>
            <HeaderMenu
              headerText={menus}
              username={this.context.user.username}
              location={this.props.children}
            />
            <Content style={{ margin: '16px' }}>
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360, height: '100%', overflow: 'auto' }}
              >
                {this.props.children}
              </div>
            </Content>
            {/* <Footer style={{ textAlign: 'center' }}>Develop by HAUP</Footer> */}
          </Layout>
        </Layout>
      </>
    )
  }
}

export default Page
