import {
  ControlOutlined,
  HomeOutlined,
  LogoutOutlined,
  MoneyCollectOutlined,
  TransactionOutlined,
} from '@ant-design/icons'

export const menus = [
  { key: 'Reservation', title: 'Reservation', link: '/', icon: TransactionOutlined },
  { key: 'Charger', title: 'Charger', link: '/charger', icon: ControlOutlined },
  { key: 'Station', title: 'Station', link: '/station', icon: HomeOutlined },
  {
    key: 'Pricing',
    title: 'Pricing',
    link: '/charger/pricing',
    icon: MoneyCollectOutlined,
    onlyAdmin: false,
  },
  { key: 'Logout', title: 'Logout', link: '/logout', icon: LogoutOutlined },
]
